import { Amplify, Auth, API, graphqlOperation } from "aws-amplify";
import { Authenticator, AuthenticatorProps } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import styles from "./App.module.scss";
import awsExports from "./aws-exports";
import Home from "./Screens/Home/Home";
import { FloatButton } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Screens/About/About";
import Layout from "./Components/Layout/Layout";
import { Login } from "./Screens/Login/Login";
//import { RequireAuth } from "./RequireAuth";
import CallbackScreen from "./Screens/Callback/CallbackScreen";
import { RequireAuth } from "./RequireAuth";

Amplify.configure(awsExports);

/*
const t =   (<Authenticator {...authenticatorProps}>
{({ signOut, user }) => { (
  <main>
   
    <Home user={user} />
  </main>
)}
</Authenticator>)
*/

export default function App() {
  return (
    <Authenticator.Provider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="/callback" element={<CallbackScreen />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
          </Route>
        </Routes>
      </Router>
    </Authenticator.Provider>
  );
}
