// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Mode = {
  "IMG": "IMG",
  "TXT": "TXT"
};

const { Conversation, Response, Request } = initSchema(schema);

export {
  Conversation,
  Response,
  Request,
  Mode
};