/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateConversationInput = {
  id?: string | null,
  user?: string | null,
  startedAt?: string | null,
  mode?: Mode | null,
  _version?: number | null,
};

export enum Mode {
  IMG = "IMG",
  TXT = "TXT",
}


export type ModelConversationConditionInput = {
  user?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  mode?: ModelModeInput | null,
  and?: Array< ModelConversationConditionInput | null > | null,
  or?: Array< ModelConversationConditionInput | null > | null,
  not?: ModelConversationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelModeInput = {
  eq?: Mode | null,
  ne?: Mode | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Conversation = {
  __typename: "Conversation",
  id: string,
  user?: string | null,
  startedAt?: string | null,
  Requests?: ModelRequestConnection | null,
  Responses?: ModelResponseConnection | null,
  mode?: Mode | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelRequestConnection = {
  __typename: "ModelRequestConnection",
  items:  Array<Request | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Request = {
  __typename: "Request",
  id: string,
  user?: string | null,
  mode?: Mode | null,
  prompt?: string | null,
  conversationID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelResponseConnection = {
  __typename: "ModelResponseConnection",
  items:  Array<Response | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Response = {
  __typename: "Response",
  id: string,
  mode?: Mode | null,
  payload?: string | null,
  createdAt?: string | null,
  Request?: Request | null,
  conversationID: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  responseRequestId?: string | null,
};

export type UpdateConversationInput = {
  id: string,
  user?: string | null,
  startedAt?: string | null,
  mode?: Mode | null,
  _version?: number | null,
};

export type DeleteConversationInput = {
  id: string,
  _version?: number | null,
};

export type CreateResponseInput = {
  id?: string | null,
  mode?: Mode | null,
  payload?: string | null,
  createdAt?: string | null,
  conversationID: string,
  _version?: number | null,
  responseRequestId?: string | null,
};

export type ModelResponseConditionInput = {
  mode?: ModelModeInput | null,
  payload?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  conversationID?: ModelIDInput | null,
  and?: Array< ModelResponseConditionInput | null > | null,
  or?: Array< ModelResponseConditionInput | null > | null,
  not?: ModelResponseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  responseRequestId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateResponseInput = {
  id: string,
  mode?: Mode | null,
  payload?: string | null,
  createdAt?: string | null,
  conversationID?: string | null,
  _version?: number | null,
  responseRequestId?: string | null,
};

export type DeleteResponseInput = {
  id: string,
  _version?: number | null,
};

export type CreateRequestInput = {
  id?: string | null,
  user?: string | null,
  mode?: Mode | null,
  prompt?: string | null,
  conversationID: string,
  _version?: number | null,
};

export type ModelRequestConditionInput = {
  user?: ModelStringInput | null,
  mode?: ModelModeInput | null,
  prompt?: ModelStringInput | null,
  conversationID?: ModelIDInput | null,
  and?: Array< ModelRequestConditionInput | null > | null,
  or?: Array< ModelRequestConditionInput | null > | null,
  not?: ModelRequestConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateRequestInput = {
  id: string,
  user?: string | null,
  mode?: Mode | null,
  prompt?: string | null,
  conversationID?: string | null,
  _version?: number | null,
};

export type DeleteRequestInput = {
  id: string,
  _version?: number | null,
};

export type ModelConversationFilterInput = {
  id?: ModelIDInput | null,
  user?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  mode?: ModelModeInput | null,
  and?: Array< ModelConversationFilterInput | null > | null,
  or?: Array< ModelConversationFilterInput | null > | null,
  not?: ModelConversationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelConversationConnection = {
  __typename: "ModelConversationConnection",
  items:  Array<Conversation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelResponseFilterInput = {
  id?: ModelIDInput | null,
  mode?: ModelModeInput | null,
  payload?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  conversationID?: ModelIDInput | null,
  and?: Array< ModelResponseFilterInput | null > | null,
  or?: Array< ModelResponseFilterInput | null > | null,
  not?: ModelResponseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  responseRequestId?: ModelIDInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelRequestFilterInput = {
  id?: ModelIDInput | null,
  user?: ModelStringInput | null,
  mode?: ModelModeInput | null,
  prompt?: ModelStringInput | null,
  conversationID?: ModelIDInput | null,
  and?: Array< ModelRequestFilterInput | null > | null,
  or?: Array< ModelRequestFilterInput | null > | null,
  not?: ModelRequestFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionConversationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user?: ModelSubscriptionStringInput | null,
  startedAt?: ModelSubscriptionStringInput | null,
  mode?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConversationFilterInput | null > | null,
  or?: Array< ModelSubscriptionConversationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionResponseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  mode?: ModelSubscriptionStringInput | null,
  payload?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  conversationID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionResponseFilterInput | null > | null,
  or?: Array< ModelSubscriptionResponseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user?: ModelSubscriptionStringInput | null,
  mode?: ModelSubscriptionStringInput | null,
  prompt?: ModelSubscriptionStringInput | null,
  conversationID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionRequestFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateConversationMutationVariables = {
  input: CreateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversation",
    id: string,
    user?: string | null,
    startedAt?: string | null,
    Requests?:  {
      __typename: "ModelRequestConnection",
      items:  Array< {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        mode?: Mode | null,
        payload?: string | null,
        createdAt?: string | null,
        conversationID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        responseRequestId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mode?: Mode | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConversationMutationVariables = {
  input: UpdateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type UpdateConversationMutation = {
  updateConversation?:  {
    __typename: "Conversation",
    id: string,
    user?: string | null,
    startedAt?: string | null,
    Requests?:  {
      __typename: "ModelRequestConnection",
      items:  Array< {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        mode?: Mode | null,
        payload?: string | null,
        createdAt?: string | null,
        conversationID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        responseRequestId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mode?: Mode | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConversationMutationVariables = {
  input: DeleteConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type DeleteConversationMutation = {
  deleteConversation?:  {
    __typename: "Conversation",
    id: string,
    user?: string | null,
    startedAt?: string | null,
    Requests?:  {
      __typename: "ModelRequestConnection",
      items:  Array< {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        mode?: Mode | null,
        payload?: string | null,
        createdAt?: string | null,
        conversationID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        responseRequestId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mode?: Mode | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateResponseMutationVariables = {
  input: CreateResponseInput,
  condition?: ModelResponseConditionInput | null,
};

export type CreateResponseMutation = {
  createResponse?:  {
    __typename: "Response",
    id: string,
    mode?: Mode | null,
    payload?: string | null,
    createdAt?: string | null,
    Request?:  {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    conversationID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    responseRequestId?: string | null,
  } | null,
};

export type UpdateResponseMutationVariables = {
  input: UpdateResponseInput,
  condition?: ModelResponseConditionInput | null,
};

export type UpdateResponseMutation = {
  updateResponse?:  {
    __typename: "Response",
    id: string,
    mode?: Mode | null,
    payload?: string | null,
    createdAt?: string | null,
    Request?:  {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    conversationID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    responseRequestId?: string | null,
  } | null,
};

export type DeleteResponseMutationVariables = {
  input: DeleteResponseInput,
  condition?: ModelResponseConditionInput | null,
};

export type DeleteResponseMutation = {
  deleteResponse?:  {
    __typename: "Response",
    id: string,
    mode?: Mode | null,
    payload?: string | null,
    createdAt?: string | null,
    Request?:  {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    conversationID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    responseRequestId?: string | null,
  } | null,
};

export type CreateRequestMutationVariables = {
  input: CreateRequestInput,
  condition?: ModelRequestConditionInput | null,
};

export type CreateRequestMutation = {
  createRequest?:  {
    __typename: "Request",
    id: string,
    user?: string | null,
    mode?: Mode | null,
    prompt?: string | null,
    conversationID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRequestMutationVariables = {
  input: UpdateRequestInput,
  condition?: ModelRequestConditionInput | null,
};

export type UpdateRequestMutation = {
  updateRequest?:  {
    __typename: "Request",
    id: string,
    user?: string | null,
    mode?: Mode | null,
    prompt?: string | null,
    conversationID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRequestMutationVariables = {
  input: DeleteRequestInput,
  condition?: ModelRequestConditionInput | null,
};

export type DeleteRequestMutation = {
  deleteRequest?:  {
    __typename: "Request",
    id: string,
    user?: string | null,
    mode?: Mode | null,
    prompt?: string | null,
    conversationID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ProductDescriptionQueryVariables = {
  name: string,
  notes: string,
};

export type ProductDescriptionQuery = {
  productDescription: string,
};

export type OpenAIPyQueryVariables = {
  name: string,
  notes: string,
};

export type OpenAIPyQuery = {
  openAIPy: string,
};

export type OpenAINodeQueryVariables = {
  name: string,
  notes: string,
};

export type OpenAINodeQuery = {
  openAINode: string,
};

export type GetConversationQueryVariables = {
  id: string,
};

export type GetConversationQuery = {
  getConversation?:  {
    __typename: "Conversation",
    id: string,
    user?: string | null,
    startedAt?: string | null,
    Requests?:  {
      __typename: "ModelRequestConnection",
      items:  Array< {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        mode?: Mode | null,
        payload?: string | null,
        createdAt?: string | null,
        conversationID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        responseRequestId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mode?: Mode | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConversationsQueryVariables = {
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConversationsQuery = {
  listConversations?:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      id: string,
      user?: string | null,
      startedAt?: string | null,
      Requests?:  {
        __typename: "ModelRequestConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Responses?:  {
        __typename: "ModelResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      mode?: Mode | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConversationsQueryVariables = {
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConversationsQuery = {
  syncConversations?:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      id: string,
      user?: string | null,
      startedAt?: string | null,
      Requests?:  {
        __typename: "ModelRequestConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Responses?:  {
        __typename: "ModelResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      mode?: Mode | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetResponseQueryVariables = {
  id: string,
};

export type GetResponseQuery = {
  getResponse?:  {
    __typename: "Response",
    id: string,
    mode?: Mode | null,
    payload?: string | null,
    createdAt?: string | null,
    Request?:  {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    conversationID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    responseRequestId?: string | null,
  } | null,
};

export type ListResponsesQueryVariables = {
  filter?: ModelResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResponsesQuery = {
  listResponses?:  {
    __typename: "ModelResponseConnection",
    items:  Array< {
      __typename: "Response",
      id: string,
      mode?: Mode | null,
      payload?: string | null,
      createdAt?: string | null,
      Request?:  {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      conversationID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      responseRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncResponsesQueryVariables = {
  filter?: ModelResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncResponsesQuery = {
  syncResponses?:  {
    __typename: "ModelResponseConnection",
    items:  Array< {
      __typename: "Response",
      id: string,
      mode?: Mode | null,
      payload?: string | null,
      createdAt?: string | null,
      Request?:  {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      conversationID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      responseRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ResponsesByConversationIDQueryVariables = {
  conversationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ResponsesByConversationIDQuery = {
  responsesByConversationID?:  {
    __typename: "ModelResponseConnection",
    items:  Array< {
      __typename: "Response",
      id: string,
      mode?: Mode | null,
      payload?: string | null,
      createdAt?: string | null,
      Request?:  {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      conversationID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      responseRequestId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRequestQueryVariables = {
  id: string,
};

export type GetRequestQuery = {
  getRequest?:  {
    __typename: "Request",
    id: string,
    user?: string | null,
    mode?: Mode | null,
    prompt?: string | null,
    conversationID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRequestsQueryVariables = {
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequestsQuery = {
  listRequests?:  {
    __typename: "ModelRequestConnection",
    items:  Array< {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRequestsQueryVariables = {
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRequestsQuery = {
  syncRequests?:  {
    __typename: "ModelRequestConnection",
    items:  Array< {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RequestsByConversationIDQueryVariables = {
  conversationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestsByConversationIDQuery = {
  requestsByConversationID?:  {
    __typename: "ModelRequestConnection",
    items:  Array< {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
};

export type OnCreateConversationSubscription = {
  onCreateConversation?:  {
    __typename: "Conversation",
    id: string,
    user?: string | null,
    startedAt?: string | null,
    Requests?:  {
      __typename: "ModelRequestConnection",
      items:  Array< {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        mode?: Mode | null,
        payload?: string | null,
        createdAt?: string | null,
        conversationID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        responseRequestId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mode?: Mode | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
};

export type OnUpdateConversationSubscription = {
  onUpdateConversation?:  {
    __typename: "Conversation",
    id: string,
    user?: string | null,
    startedAt?: string | null,
    Requests?:  {
      __typename: "ModelRequestConnection",
      items:  Array< {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        mode?: Mode | null,
        payload?: string | null,
        createdAt?: string | null,
        conversationID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        responseRequestId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mode?: Mode | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
};

export type OnDeleteConversationSubscription = {
  onDeleteConversation?:  {
    __typename: "Conversation",
    id: string,
    user?: string | null,
    startedAt?: string | null,
    Requests?:  {
      __typename: "ModelRequestConnection",
      items:  Array< {
        __typename: "Request",
        id: string,
        user?: string | null,
        mode?: Mode | null,
        prompt?: string | null,
        conversationID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        mode?: Mode | null,
        payload?: string | null,
        createdAt?: string | null,
        conversationID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        responseRequestId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    mode?: Mode | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateResponseSubscriptionVariables = {
  filter?: ModelSubscriptionResponseFilterInput | null,
};

export type OnCreateResponseSubscription = {
  onCreateResponse?:  {
    __typename: "Response",
    id: string,
    mode?: Mode | null,
    payload?: string | null,
    createdAt?: string | null,
    Request?:  {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    conversationID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    responseRequestId?: string | null,
  } | null,
};

export type OnUpdateResponseSubscriptionVariables = {
  filter?: ModelSubscriptionResponseFilterInput | null,
};

export type OnUpdateResponseSubscription = {
  onUpdateResponse?:  {
    __typename: "Response",
    id: string,
    mode?: Mode | null,
    payload?: string | null,
    createdAt?: string | null,
    Request?:  {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    conversationID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    responseRequestId?: string | null,
  } | null,
};

export type OnDeleteResponseSubscriptionVariables = {
  filter?: ModelSubscriptionResponseFilterInput | null,
};

export type OnDeleteResponseSubscription = {
  onDeleteResponse?:  {
    __typename: "Response",
    id: string,
    mode?: Mode | null,
    payload?: string | null,
    createdAt?: string | null,
    Request?:  {
      __typename: "Request",
      id: string,
      user?: string | null,
      mode?: Mode | null,
      prompt?: string | null,
      conversationID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    conversationID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    responseRequestId?: string | null,
  } | null,
};

export type OnCreateRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRequestFilterInput | null,
};

export type OnCreateRequestSubscription = {
  onCreateRequest?:  {
    __typename: "Request",
    id: string,
    user?: string | null,
    mode?: Mode | null,
    prompt?: string | null,
    conversationID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRequestFilterInput | null,
};

export type OnUpdateRequestSubscription = {
  onUpdateRequest?:  {
    __typename: "Request",
    id: string,
    user?: string | null,
    mode?: Mode | null,
    prompt?: string | null,
    conversationID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRequestSubscriptionVariables = {
  filter?: ModelSubscriptionRequestFilterInput | null,
};

export type OnDeleteRequestSubscription = {
  onDeleteRequest?:  {
    __typename: "Request",
    id: string,
    user?: string | null,
    mode?: Mode | null,
    prompt?: string | null,
    conversationID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
