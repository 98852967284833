import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import {
  CreateConversationInput,
  CreateConversationMutation,
  ListResponsesQuery,
  ListResponsesQueryVariables,
} from "../API";
import { listResponses } from "../graphql/queries";
import { createConversation } from "../graphql/mutations";
import { Mode } from "../models";
import { ConversationData, ConversationMode, FeedEntries } from "../Types/Feed";
import { create } from "domain";

export const newConversation = async (
  user: string,
  promptMode: Mode
): Promise<ConversationData | undefined> => {
  //console.log("Creating New Conversation");

  const variables: CreateConversationInput = {
    user,
    mode: promptMode,
    startedAt: new Date(Date.now()).toISOString(),
  };

  //console.log(variables);
  const results = await API.graphql<GraphQLQuery<CreateConversationMutation>>(
    graphqlOperation(createConversation, { input: variables })
  );

  const id = results.data?.createConversation?.id;

  if (id) {
    const data: ConversationData = {
      id,
      user: results.data?.createConversation?.user
        ? results.data?.createConversation?.user
        : "hacker@yahoo.com",
      mode: promptMode,
      info: undefined,
      feed: [],
      lastUpdated: new Date(Date.now()),
    };
    return data;
  } else {
    return undefined;
  }
};
