/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const productDescription = /* GraphQL */ `
  query ProductDescription($name: String!, $notes: String!) {
    productDescription(name: $name, notes: $notes)
  }
`;
export const openAIPy = /* GraphQL */ `
  query OpenAIPy($name: String!, $notes: String!) {
    openAIPy(name: $name, notes: $notes)
  }
`;
export const openAINode = /* GraphQL */ `
  query OpenAINode($name: String!, $notes: String!) {
    openAINode(name: $name, notes: $notes)
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      user
      startedAt
      Requests {
        items {
          id
          user
          mode
          prompt
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Responses {
        items {
          id
          mode
          payload
          createdAt
          conversationID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          responseRequestId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        startedAt
        Requests {
          nextToken
          startedAt
          __typename
        }
        Responses {
          nextToken
          startedAt
          __typename
        }
        mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncConversations = /* GraphQL */ `
  query SyncConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user
        startedAt
        Requests {
          nextToken
          startedAt
          __typename
        }
        Responses {
          nextToken
          startedAt
          __typename
        }
        mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      mode
      payload
      createdAt
      Request {
        id
        user
        mode
        prompt
        conversationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      conversationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      responseRequestId
      __typename
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mode
        payload
        createdAt
        Request {
          id
          user
          mode
          prompt
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        conversationID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        responseRequestId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncResponses = /* GraphQL */ `
  query SyncResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mode
        payload
        createdAt
        Request {
          id
          user
          mode
          prompt
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        conversationID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        responseRequestId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const responsesByConversationID = /* GraphQL */ `
  query ResponsesByConversationID(
    $conversationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesByConversationID(
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mode
        payload
        createdAt
        Request {
          id
          user
          mode
          prompt
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        conversationID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        responseRequestId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      user
      mode
      prompt
      conversationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        mode
        prompt
        conversationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRequests = /* GraphQL */ `
  query SyncRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user
        mode
        prompt
        conversationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const requestsByConversationID = /* GraphQL */ `
  query RequestsByConversationID(
    $conversationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestsByConversationID(
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        mode
        prompt
        conversationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
