import { Collapse } from "antd";
import React, { ReactNode } from "react";
import { MessageList } from "..";
import { ConversationData } from "../../Types/Feed";
import styles from "./Conversation.module.scss";

interface ConversationHeaderProps {
  conversation: ConversationData;
}
const ConversationHeader = ({ conversation }: ConversationHeaderProps) => {
  return <div>Conversation</div>;
};

interface ConversationProps {
  conversation: ConversationData;
}

const Conversation = ({ conversation }: ConversationProps) => {
  const label: ReactNode = (
    <div className={styles.ConversationHeader}>
      {conversation.mode} Feed{" "}
      <span className={styles.ByLine}>
        by {conversation.user} last @{" "}
        {conversation.lastUpdated.toLocaleString()}
      </span>
      ;
    </div>
  );

  return (
    <div className={styles.Conversation}>
      <Collapse
        style={{ width: 500 }}
        items={[
          {
            key: "1",
            label: label,
            children: [
              <div>
                <MessageList entries={conversation.feed} />
              </div>,
            ],
          },
        ]}
      />
    </div>
  );
};

export default Conversation;
