import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

interface RequireAuthProps {
  children: any;
}
export const RequireAuth = ({ children }: RequireAuthProps) => {
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated") {
    return <Navigate to="/login" />;
  }
  return children;
};
