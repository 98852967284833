import { Button, Card, Input, Space, Switch } from "antd";
import React, { useState } from "react";
import { PromptRequest } from "../../Types/Feed";
import styles from "./Prompter.module.scss";
import { AmplifyUser } from "@aws-amplify/ui";
import { userInfo } from "os";
import { Mode } from "../../models";
interface PrompterProps {
  mode: Mode;
  user: AmplifyUser | undefined;
  onPrompt: (request: PromptRequest) => void;
  loading: boolean;
}
const Prompter = ({ mode, user, onPrompt, loading }: PrompterProps) => {
  const [promptText, setPromptText] = useState<string>();
  const onChangeHandlerPrompt = (event: any) => {
    //console.log(event.target.value);
    //TODO: add some predictive text processing here
    setPromptText(event.target.value);
  };

  const onClickHandlerGenerate = () => {
    //console.log(promptText);
    if (promptText) {
      const userData = user?.attributes?.email
        ? user.attributes.email
        : "anonymous user";
      const request: PromptRequest = {
        user: userData,
        text: promptText ? promptText : "",
        mode: mode,
      };
      onPrompt(request);
    }
  };

  return (
    <Card style={{ width: 500 }}>
      <div className={styles.PrompterInput}>
        <Space.Compact style={{ width: "100%" }}>
          <Input
            style={{ width: 300 }}
            placeholder="Enter Text Prompt Here"
            onChange={onChangeHandlerPrompt}
          />
          <Button
            type="primary"
            loading={loading}
            onClick={onClickHandlerGenerate}
          >
            Generate
          </Button>
        </Space.Compact>
      </div>
    </Card>
  );
};

export default Prompter;
