import { GraphQLQuery } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { ListResponsesQuery, ListResponsesQueryVariables } from "../API";
import { listResponses } from "../graphql/queries";
import { Mode } from "../models";
import { FeedEntries } from "../Types/Feed";

export const getConversationFeed = async (
  conversationId: string
): Promise<FeedEntries> => {
  //console.log("GETTING FEED");

  const variables: ListResponsesQueryVariables = {
    filter: { conversationID: { eq: conversationId } },
  };

  const results = await API.graphql<GraphQLQuery<ListResponsesQuery>>(
    graphqlOperation(listResponses, variables)
  );

  const items = results.data?.listResponses?.items;

  const responses: FeedEntries = [];

  if (items) {
    const sortedItems = items.sort((a, b) => {
      if (a && a.createdAt && b && b.createdAt) {
        return Date.parse(b.createdAt) - Date.parse(a.createdAt);
      } else {
        return -1;
      }
    });

    //console.log("GOT", { sortedItems });
    for (let i = 0; i < sortedItems?.length; i++) {
      const item = sortedItems[i];
      if (item) {
        const requestResult = item.Request;
        let type: Mode = Mode.IMG;
        if (item.mode == Mode.TXT) type = Mode.TXT;

        if (
          requestResult &&
          requestResult.prompt &&
          requestResult.user &&
          item.payload
        ) {
          const responseData = {
            id: item.id,
            type,
            payload: JSON.parse(item.payload),
            date: item.updatedAt,
            request: {
              user: requestResult.user,
              text: requestResult.prompt,
              mode: type,
            },
          };
          //console.log("RESPONSE:", { responseData });
          responses.push(responseData);
        }
      }
    }
  }

  //console.log({ responses });
  return responses;
};
