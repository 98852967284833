/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      user
      startedAt
      Requests {
        items {
          id
          user
          mode
          prompt
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Responses {
        items {
          id
          mode
          payload
          createdAt
          conversationID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          responseRequestId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      user
      startedAt
      Requests {
        items {
          id
          user
          mode
          prompt
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Responses {
        items {
          id
          mode
          payload
          createdAt
          conversationID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          responseRequestId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      user
      startedAt
      Requests {
        items {
          id
          user
          mode
          prompt
          conversationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Responses {
        items {
          id
          mode
          payload
          createdAt
          conversationID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          responseRequestId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      mode
      payload
      createdAt
      Request {
        id
        user
        mode
        prompt
        conversationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      conversationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      responseRequestId
      __typename
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      mode
      payload
      createdAt
      Request {
        id
        user
        mode
        prompt
        conversationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      conversationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      responseRequestId
      __typename
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      mode
      payload
      createdAt
      Request {
        id
        user
        mode
        prompt
        conversationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      conversationID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      responseRequestId
      __typename
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      user
      mode
      prompt
      conversationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      user
      mode
      prompt
      conversationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      user
      mode
      prompt
      conversationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
