import React, { Children } from "react";
import {
  ImgEntryPayload,
  PromptRequest,
  FeedEntry,
  TxtEntryPayload,
} from "../../Types/Feed";
import { Card, Collapse, CollapseProps, Image } from "antd";
import { Mode } from "../../models";
import styles from "./MessageList.module.scss";
interface EntryProps {
  entry: FeedEntry<Mode>;
}

interface TxtEntryProps {
  msgtxt: string;
}

const TxtEntry = ({ msgtxt }: TxtEntryProps) => {
  return <div className={styles.TxtEntry}>{msgtxt}</div>;
};

interface ImgEntryProps {
  url: string;
}

const ImgEntry = ({ url }: ImgEntryProps) => {
  return (
    <div className={styles.ImgEntry}>
      <Image
        className={styles.ImgEntryImage}
        src={"/images/placeholder.png"}
        width={450}
      />
    </div>
  );
};

interface EntryResponseProps {
  children: React.ReactNode;
}
interface EntryRequestProps {
  children: React.ReactNode;
}

const EntryResponse = ({ children }: EntryResponseProps) => {
  return <div className={styles.EntryResponse}>{children}</div>;
};

const EntryRequest = ({ children }: EntryRequestProps) => {
  return <div className={styles.EntryRequest}>{children}</div>;
};

const Entry = ({ entry }: EntryProps) => {
  const buildEntry = () => {
    switch (entry.type) {
      case Mode.IMG:
        return (
          <div className={styles.Entry}>
            <ImgEntry url={(entry.payload as ImgEntryPayload).url} />
          </div>
        );
        break;
      case Mode.TXT:
        return (
          <div className={styles.Entry}>
            <TxtEntry msgtxt={(entry.payload as TxtEntryPayload).result} />
          </div>
        );
        break;
      default:
        return <div className={styles.Entry}></div>;
    }
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className={styles.RequestCollapseHeader}
        >{`prompt: ${entry.request.text}`}</div>
      ),
      children: (
        <EntryRequest>
          <div>{`Request made by ${entry.request.user} at ${entry.date}`}</div>
        </EntryRequest>
      ),
    },
  ];
  return (
    <div className={styles.Entry}>
      <EntryResponse>{buildEntry()}</EntryResponse>
      <Collapse className={styles.EntryCollapse} items={items} />
    </div>
  );
};

export default Entry;
