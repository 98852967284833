import { API, graphqlOperation } from "aws-amplify";
import { useAuthenticator, Heading } from "@aws-amplify/ui-react";
import { GraphQLQuery } from "@aws-amplify/api";
import { listConversations, openAIPy } from "../../graphql/queries";
import {
  CreateRequestInput,
  Mode,
  CreateRequestMutation,
  CreateResponseMutation,
  CreateResponseInput,
  ListConversationsQueryVariables,
  ListConversationsQuery,
  OpenAIPyQuery,
  OpenAIPyQueryVariables,
} from "../../API";

import { Card, Input, Radio, Segmented, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { FeedHeader, Prompter, SiteHeader } from "../../Components";
import {
  PromptRequest,
  ConversationData,
  FeedEntries,
  ConversationMode,
} from "../../Types/Feed";
import styles from "./Home.module.scss";
import { createRequest, createResponse } from "../../graphql/mutations";
import { ConversationComponent as Conversation } from "../../Components";
import { AmplifyUser } from "@aws-amplify/ui";
import { getConversationFeed } from "../../API/getConversationFeed";
import { newConversation } from "../../API/newConversation";

import Auth from "@aws-amplify/auth";
import Lambda from "aws-sdk/clients/lambda"; // npm install aws-sdk

const DEFAULTPAGECOUNT = 10;

interface HomeProps {}

const Home = ({}: HomeProps) => {
  const { user, route } = useAuthenticator((context) => [context.route]);
  const [loading, setLoading] = useState<boolean>(false);
  console.log(user, route);

  let userstring = "hacker@yahoo.com";
  //@ts-ignore
  if (user.email) userstring = user.email;
  if (user.attributes?.email) userstring = user.attributes.email;
  const defaultConversation: ConversationData = {
    user: userstring,
    id: "",
    mode: Mode.TXT,
    info: undefined,
    feed: [],
    lastUpdated: new Date(),
  };

  const [updated, setUpdated] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>(Mode.TXT);
  const [conversationMode, setConversationMode] = useState<ConversationMode>(
    ConversationMode.LOCAL
  );
  const [localConversation, setLocalConversation] = useState<
    ConversationData | undefined
  >(defaultConversation);

  const [publicConversations, setPublicConversations] = useState<
    Array<ConversationData> | undefined
  >();

  const makeRequest = async (request: PromptRequest) => {
    setLoading(true);
    if (localConversation) {
      // console.log(localConversation);
      let id: string | undefined = localConversation.id;
      if (!id) id = await initiateConversation();
      if (id) {
        //console.log({ id });
        let requestMode = Mode.TXT;
        if (request.mode == Mode.IMG) {
          requestMode = Mode.IMG;
        }

        const requestInput: CreateRequestInput = {
          user: request.user,
          prompt: `${request.text}`,
          mode: requestMode,
          conversationID: id,
        };
        //console.log("STORING REQUEST", { requestInput });
        //hit the api with the request, store it in promptResult
        const result = await API.graphql<GraphQLQuery<CreateRequestMutation>>(
          graphqlOperation(createRequest, { input: requestInput })
        );

        if (result.data && result.data.createRequest) {
          const requestId = result.data?.createRequest.id;

          if (requestInput.prompt) {
            const gptreq: OpenAIPyQueryVariables = {
              name: "building",
              notes: requestInput.prompt ? requestInput.prompt : "",
            };
            const gptresponse = await API.graphql<GraphQLQuery<OpenAIPyQuery>>(
              graphqlOperation(openAIPy, gptreq)
            );
            const resultString = gptresponse.data?.openAIPy;
            const payload = JSON.parse(resultString ? resultString : "");
            const useString = payload.body ? payload.body : "";

            const formattedString = useString.replace(
              /\\u([\dA-F]{4})/gi,
              (match: any, p1: string) => String.fromCharCode(parseInt(p1, 16))
            );

            /*const lambda = new Lambda({
              credentials: Auth.essentialCredentials(credentials),
            });
            const response2 = lambda.invoke({
              FunctionName: "my-function",
              Payload: JSON.stringify({ hello: world }),
            });*/

            const responseInput: CreateResponseInput = {
              mode: requestMode,
              responseRequestId: requestId,
              payload: JSON.stringify({
                url: "http://someimage.jpg",
                result: formattedString,
              }),
              conversationID: id,
            };

            //console.log("MAKING REQUEST");
            const response = await API.graphql<
              GraphQLQuery<CreateResponseMutation>
            >(graphqlOperation(createResponse, { input: responseInput }));

            if (response && response.data && response.data.createResponse) {
              setLoading(false);
              setUpdated(true);
              //console.log("UPDATING FEED");
              /*setLocalConversation({
                ...localConversation,
                feed: await getConversation(id),
              });*/
            }
          }
        }
      }
    }
  };

  const updateFeed = () => {
    switch (conversationMode) {
      case ConversationMode.LOCAL:
        getLocalConversation();
        break;
      case ConversationMode.PUBLIC:
        getPublicConversation();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (updated) {
      updateFeed();
      setUpdated(false);
    }
  }, [updated]);

  useEffect(() => {
    console.log("USER UE", user);
  }, [user]);

  const initiateConversation = async (): Promise<string | undefined> => {
    if (userstring) {
      const convo = await newConversation(userstring, mode);

      if (convo) {
        //console.log("CREATED A NEW CONVERSATION", convo);
        setLocalConversation(convo);
        return convo?.id;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const getLocalConversation = async () => {
    if (localConversation) {
      let id = localConversation.id;
      let conversationFeed: FeedEntries = [];
      //console.log("GETTING THE FEED FOR THE CONVO");
      conversationFeed = await getConversationFeed(id);
      if (conversationFeed && conversationFeed.length > 0) {
        setLocalConversation({ ...localConversation, feed: conversationFeed });
      }
    }
  };

  useEffect(() => {
    //console.log({ localConversation });
  }, [localConversation, publicConversations]);

  const getPublicConversation = async () => {
    const newPublicFeed: Array<ConversationData> = [];

    const variables: ListConversationsQueryVariables = {
      limit: DEFAULTPAGECOUNT,
    };

    const results = await API.graphql<GraphQLQuery<ListConversationsQuery>>(
      graphqlOperation(listConversations, variables)
    );

    const items = results.data?.listConversations?.items;

    //console.log(items);
    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        const conversationItem = items[i];
        if (conversationItem) {
          const conversationFeed = await getConversationFeed(
            conversationItem.id
          );
          conversationFeed.sort((a, b) => {
            return Date.parse(b.date) - Date.parse(a.date);
          });

          if (conversationFeed) {
            //console.log({ conversationFeed });
            const lastUpdated = new Date(conversationFeed[0].date);
            const convoData: ConversationData = {
              user: conversationItem.user
                ? conversationItem.user
                : userstring
                ? userstring
                : "hacker@yahoo.com",
              id: conversationItem.id,
              mode: conversationItem.mode ? conversationItem.mode : Mode.TXT,
              info: undefined,
              feed: conversationFeed,
              lastUpdated: lastUpdated,
            };
            newPublicFeed.push(convoData);
          }
        }
      }
    }

    if (newPublicFeed.length > 0) {
      newPublicFeed.sort((a, b) => {
        return b.lastUpdated.valueOf() - a.lastUpdated.valueOf();
      });
      setPublicConversations(newPublicFeed);
    }
  };

  useEffect(() => {
    switch (conversationMode) {
      case ConversationMode.LOCAL:
        getLocalConversation();
        break;
      case ConversationMode.PUBLIC:
        getPublicConversation();
        break;
      default:
        break;
    }
  }, [conversationMode]);

  //bump

  const onModeChangeHandlerMode = (event: boolean) => {
    if (event) {
      setMode(Mode.TXT);
    } else {
      setMode(Mode.IMG);
    }
  };

  const onFeedChangeHandler = (event: boolean) => {
    if (event) {
      setConversationMode(ConversationMode.PUBLIC);
    } else {
      setConversationMode(ConversationMode.LOCAL);
    }
  };

  return (
    <div className={styles.HomeScreen}>
      <Space className={styles.MainStack} direction="vertical" size={"small"}>
        <div className={styles.SiteHeader}>
          <Space.Compact direction="vertical" size="small">
            <div className={styles.FeedMode}>Prompt Mode</div>
            <Radio.Group value={mode} onChange={(e) => setMode(e.target.value)}>
              <Radio.Button disabled={mode == Mode.TXT} value={Mode.TXT}>
                Image
              </Radio.Button>
              <Radio.Button disabled={mode == Mode.IMG} value={Mode.IMG}>
                Text
              </Radio.Button>
            </Radio.Group>
          </Space.Compact>
          <div className={styles.SiteHeaderBranding}>
            <SiteHeader />
          </div>
        </div>

        <Card className={styles.PromptSpace}>
          <Prompter
            user={user}
            mode={mode}
            onPrompt={makeRequest}
            loading={loading}
          />
        </Card>

        <div className={styles.FeedHeader}>
          <Space.Compact direction="vertical" size="small">
            <div className={styles.FeedMode}>Feed Mode</div>
            <Radio.Group
              value={conversationMode}
              onChange={(e) => setConversationMode(e.target.value)}
            >
              <Radio.Button
                disabled={conversationMode == ConversationMode.PUBLIC}
                value={ConversationMode.PUBLIC}
              >
                Local
              </Radio.Button>
              <Radio.Button
                disabled={conversationMode == ConversationMode.LOCAL}
                value={ConversationMode.LOCAL}
              >
                Public
              </Radio.Button>
            </Radio.Group>
          </Space.Compact>
          <FeedHeader
            mode={conversationMode}
            loc={localConversation}
            pub={publicConversations}
          />
        </div>

        <Card className={styles.FeedCard} style={{ width: 550 }}>
          {localConversation && conversationMode == ConversationMode.LOCAL && (
            <div className={styles.LocalConversation}>
              <Conversation conversation={localConversation} />
            </div>
          )}
          {publicConversations &&
            conversationMode == ConversationMode.PUBLIC && (
              <div className={styles.PublicConversation}>
                <Space direction="vertical">
                  {publicConversations.map((convo, idx) => {
                    return (
                      <Conversation key={`convo${idx}`} conversation={convo} />
                    );
                  })}
                </Space>
              </div>
            )}
          {!localConversation && !publicConversations && <div>WTF</div>}
        </Card>
      </Space>
    </div>
  );
};

export default Home;
