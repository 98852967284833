import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams, NavigateOptions } from "react-router-dom";
import { FederatedUser } from "@aws-amplify/auth/lib-esm/types";
import { useAuthenticator, Heading } from "@aws-amplify/ui-react";
import jwt_decode from "jwt-decode";

const userFromJWT = (token: string) => {
  const decoded: { email: string; iss: string; identities: [any] } =
    jwt_decode(token);

  if (decoded.identities[0]) {
    const fedUser = {
      email: decoded.identities[0].userId,
      name: decoded.identities[0].userId,
      issuer: decoded.iss,
    };
    //console.log(decoded, fedUser);
    return fedUser;
  } else {
    return {
      email: undefined,
      name: undefined,
      issuer: undefined,
    };
  }
};

const authFederatedUser = async (
  token: any,
  user: FederatedUser,
  expires_at: any,
  provider: string,
  callback: (user: any) => void
) => {
  try {
    const cred = await Auth.federatedSignIn(
      provider,
      {
        token,
        expires_at,
      },
      user
    );
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    console.log("Callback", cred, authenticatedUser);
    callback(authenticatedUser);
  } catch (err) {
    console.log(err);
  }
};

const CallbackScreen = () => {
  const { user, route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location) {
      const searchParams = new URLSearchParams(location.hash);
      const accessToken = searchParams.get("#access_token");
      const idToken = searchParams.get("id_token");
      const tokenType = searchParams.get("token_type");
      const expiresIn = searchParams.get("expires_in");

      const opts: NavigateOptions = { replace: true };
      const expire = Number.parseInt(expiresIn ? expiresIn : "360");

      if (accessToken && idToken) {
        const JWTUser = userFromJWT(idToken);
        const fedUser: FederatedUser = {
          email: JWTUser.email,
          name: JWTUser.name,
        };
        console.log("CB", user, route);
        authFederatedUser(
          idToken,
          fedUser,
          expire * 1000 + new Date().getTime(),
          "cognito-idp.us-east-1.amazonaws.com/us-east-1_e7gjSI9hH",
          (user) => {
            if (user) {
              window.location.assign("/");
            }
          }
        );
      }
    }
  }, [location]);

  return <div>Callback</div>;
};

export default CallbackScreen;
