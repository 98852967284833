import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useAuthenticator, View } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { Button, Space } from "antd";
import awsExports from "../../aws-exports";
import { useSearchParams } from "react-router-dom";

export const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  //const location = useLocation();
  const navigate = useNavigate();
  //let from = location.state?.from?.pathname || "/";
  let from = "/";
  //const idtouse = "281inl47pelcbbj59gdpim5gt6";
  //const authString = `https://${awsExports.oauth.domain}/oauth2/authorize?identity_provider=${process.env.REACT_APP_PROVIDER_NAME}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=TOKEN&client_id=${idtouse}&scope=aws.cognito.signin.user.admin%20%email%20%openid%20%phone%20%profile`;
  //const authString = `https://${awsExports.oauth.domain}/oauth2/authorize?client_id=${awsExports.aws_user_pools_web_client_id}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
  const authString = `https://${awsExports.oauth.domain}/oauth2/authorize?identity_provider=${process.env.REACT_APP_PROVIDER_NAME}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=TOKEN&client_id=${awsExports.aws_user_pools_web_client_id}&scope=aws.cognito.signin.user.admin%20%email%20%openid%20%phone%20%profile`;
  //console.log({ authString });

  const handleClick = () => {
    // do something meaningful, Promises, if/else, whatever, and then
    window.location.assign(authString);
  };

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route]);
  //, navigate, from
  return (
    <View className="auth-wrapper">
      <Space direction="vertical">
        <Authenticator></Authenticator>
        <Button onClick={handleClick}>PW_LOGIN</Button>
      </Space>
    </View>
  );
};
