import React from "react";
import { ConversationData, ConversationMode } from "../../Types/Feed";
import styles from "./FeedHeader.module.scss";

interface FeedHeaderProps {
  mode: ConversationMode;
  loc: ConversationData | undefined;
  pub: Array<ConversationData> | undefined;
}

const FeedHeader = ({ mode, loc, pub }: FeedHeaderProps) => {
  return (
    <div className={styles.FeedHeader}>
      <br></br>Feed Header
    </div>
  );
};

export default FeedHeader;
