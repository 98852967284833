import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./Layout.module.scss";
import { FloatButton } from "antd";
const Layout = () => {
  const { user, signOut } = useAuthenticator();

  return (
    <div className={styles.SiteLayout}>
      <Outlet />
      {user && (
        <FloatButton
          description="Sign Out"
          shape="square"
          style={{ right: 94 }}
          onClick={() => {
            if (signOut) {
              {
                signOut();
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default Layout;
