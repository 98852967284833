import { Mode } from "../models";

/*export enum PromptMode {
  TEXT = "text",
  IMG = "img",
}*/

export enum ConversationMode {
  STARTUP = "startup",
  LOCAL = "local",
  PUBLIC = "public",
}

export interface ConversationData {
  user: string;
  id: string;
  mode: Mode;
  info: any;
  feed: FeedEntries;
  lastUpdated: Date;
}

export interface PromptRequest {
  user: string;
  text: string;
  mode: Mode;
}

export interface ImgEntryPayload {
  url: string;
}

export interface TxtEntryPayload {
  result: string;
}

export interface Conversation {}

export interface FeedEntry<T extends Mode> {
  request: PromptRequest;
  type: T;
  id: string;
  date: string;
  payload: T extends Mode.IMG
    ? ImgEntryPayload
    : T extends Mode.TXT
    ? TxtEntryPayload
    : never;
}

export type FeedEntries = Array<FeedEntry<Mode.IMG | Mode.TXT>>;
