/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://jbm63nldzrfu5o5jik3hybqvxe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bdemlscgu5eqpk2ndyky63gjni",
    "aws_cognito_identity_pool_id": "us-east-1:9dfff1fc-1762-48f0-9bd1-2f3e9a6fdb1a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_e7gjSI9hH",
    "aws_user_pools_web_client_id": "1tiqjik9d85s9puunn9eeku5kh",
    "oauth": {
        "domain": "aiportalui94d1c785-94d1c785-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://generator.perkinswill.com/login/,http://localhost:3001/,https://aiportalui94d1c785-94d1c785-staging.auth.us-east-1.amazoncognito.com/saml2/idpresponse/,http://localhost:3001/callback/,https://generator.perkinswill.com/callback/",
        "redirectSignOut": "https://generator.perkinswill.com/signout/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
