import React from "react";
import styles from "./SiteHeader.module.scss";

const SiteHeader = () => {
  return (
    <div className={styles.SiteHeader}>
      PW AI <span className={styles.HeaderVersion}>version 1.0</span>
    </div>
  );
};

export default SiteHeader;
