import { List, Space } from "antd";
import React from "react";
import { FeedEntries } from "../../Types/Feed";
import Entry from "./Entry";
import styles from "./MessageList.module.scss";

interface MessageListProps {
  entries: FeedEntries;
}

const MessageList = ({ entries }: MessageListProps) => {
  const buildList = (listEntries: FeedEntries) => {
    return listEntries.map((entry) => {
      return entry;
    });
  };

  return (
    <div className={styles.MessageList}>
      <Space direction="vertical" size={"middle"}>
        {entries &&
          entries.map((entry) => {
            return <Entry entry={entry} />;
          })}

        {(!entries || entries.length < 1) && (
          <div>Get the conversation started!</div>
        )}
      </Space>
    </div>
  );
};

export default MessageList;
